var mycookieValue = JSON.parse(getCookie("klaro"));

if (document.getElementById('map') && mycookieValue) {
    if (mycookieValue.mapbox == true) {
        setTimeout(function () {
            initMap();
            document.getElementById('map').classList.add('active');
        }, 100)
    }
} else if (document.getElementById('map') && !mycookieValue) {
    document.getElementById('map').classList.remove('active');
}

function getCookie(name) {
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
}

function initMap() {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYXByaWxhZ2VudHVyIiwiYSI6ImNrZ2FrZnVpZTA0ZmMyeXFhNjlxcjhob2oifQ.NRgZbxLpblekz94eJ8afRg';
    const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/aprilagentur/ckgam81b415sd19lo0m6qhmnj',
        center: [-86.2440402, 12.4495263],
        zoom: 8.0,
        scrollZoom: false,
        dragPan: false,
    });

    map.on('load', function () {

        map.addLayer({
            "id": "points",
            "type": "symbol",
            "source": {
                "type": "geojson",
                "data": {
                    "type": "FeatureCollection",
                    "features": [{
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [-85.8616626, 12.7622023],
                        },
                        "properties": {
                            "title": "San Diosinio",
                        },
                    }, {
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [-85.8425797, 12.9231543],
                        },
                        "properties": {
                            "title": "San Ramón",
                        },
                    }],
                },
            },
            "layout": {
                "text-field": "{title}",
                "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                "text-offset": [3.5, 0.5],
                "icon-size": 1,
                "text-anchor": "top",
            },
            "paint": {
                "text-color": "#1a913d",
            },
        });
    });

    var geojson = {
        type: 'marker',
        features: [{
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [-85.8616626, 12.7622023],
            },
            properties: {
                title: 'San Diosinio',
                description: '',
                className: "map-marker",
            },
        }, {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [-85.8425797, 12.9231543],
            },
            properties: {
                title: 'San Ramón',
                description: '',
                className: "map-marker",
            },
        }
        ],
    };

    geojson.features.forEach(function (marker) {

        // create a HTML element for each feature
        var el = document.createElement('div');
        el.className = marker.properties.className;

        // make a marker for each feature and add to the map
        new mapboxgl.Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .addTo(map);
    });


    document.getElementById('mapInteraction').addEventListener('change', function (e) {
        if (e.target.checked) {
            map['dragPan'].enable();
            map['scrollZoom'].enable();
        } else {
            map['dragPan'].disable();
            map['scrollZoom'].disable();
        }
    });

}

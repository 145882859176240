/**
 * Toggle hamburger menu
 */
document.addEventListener('click', function (event) {

    if (!event.target.matches('.js-nav-icon') && !event.target.matches('.js-nav-icon span')) return;
    event.preventDefault();
    var icon = document.querySelector('.js-nav-icon');
    var menu = document.querySelector('.js-mainmenu');

    icon.classList.toggle('open');
    menu.classList.toggle('open');

}, false);

/**
 * Open child items in mobile menu
 */
document.addEventListener('click', function (event) {
    var el;

    if (!event.target.matches('.js-open-dropdown') && !event.target.matches('.js-open-dropdown > span') && !event.target.matches('.js-open-dropdown svg')) return;
    event.preventDefault();
    if (!event.target.matches('.js-open-dropdown')) {
        el = getClosest(event.target, '.js-open-dropdown')
    } else {
        el = event.target;
    }
    var menuBtns = document.querySelectorAll('.js-open-dropdown');
    for (var i = menuBtns.length - 1; i >= 0; i--) {
        if (menuBtns[i] !== el && menuBtns[i].className.match('active')) {
            menuBtns[i].classList.remove('active');
        }
    }
    el.classList.toggle('active');
}, false);

/**
 * Open donation box
 */
document.addEventListener('click', function (event) {

    if (!event.target.matches('.js-donate span') && !event.target.matches('.js-donate')) return;
    event.preventDefault();
    var donation = document.querySelector('.js-donationbox');
    var donationBtn = document.querySelector('.js-donate');

    donation.classList.toggle('js-hidden');
    donationBtn.classList.toggle('js-openBox');
}, false);

/**
 * Get the closest parent element with selector
 * @param elem
 * @param selector
 * @returns {*}
 */

function getClosest (elem, selector) {

    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function(s) {
                var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                    i = matches.length;
                while (--i >= 0 && matches.item(i) !== this) {}
                return i > -1;
            };
    }

    // Get the closest matching element
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
        if ( elem.matches( selector ) ) return elem;
    }
    return null;

};
import Siema from 'siema';

if (document.querySelector('.siema')) {
    const mySiema = new Siema({
        selector: '.siema',
        duration: 200,
        easing: 'ease-out',
        perPage: 1,
        startIndex: 0,
        draggable: true,
        multipleDrag: true,
        threshold: 20,
        loop: true,
        rtl: false,
        onInit: () => {
        },
        onChange: () => {
            for (let i = 0; i < mySiema.innerElements.length; i++) {
                // if current dot matches currentSlide prop, add a class to it, remove otherwise
                const addOrRemove = mySiema.currentSlide === i ? 'add' : 'remove';
                document.querySelectorAll('.siema-dots')[i].classList[addOrRemove]('dots__item--active');
            }

            next.classList.remove('disabled');
            if (mySiema.currentSlide === 0) {
                prev.classList.add('disabled')
            } else if (mySiema.currentSlide === mySiema.innerElements.length - 1) {
                next.classList.add('disabled')
            }
        },
    });

    const prev = document.querySelector('.siema-prev');
    const next = document.querySelector('.siema-next');

    prev.addEventListener('click', () => mySiema.prev());
    next.addEventListener('click', () => mySiema.next());

    const navDiv = document.createElement('div');
    navDiv.classList.add('nav');

    // check how many items we have inside a carousel
    // and create that many buttons and apend them to nav div
    // add a listened event to each of them to navigate to prticular slide
    for (let i = 0; i < mySiema.innerElements.length; i++) {
        const btn = document.createElement('button');
        // btn.textContent = i+1;
        btn.classList.add('siema-dots');
        if (i == 0) {
            btn.classList.add('dots__item--active');
        }
        btn.addEventListener('click', () => mySiema.goTo(i));
        navDiv.appendChild(btn);
    }

    // place the nav div after siema markup
    mySiema.selector.parentNode.insertBefore(navDiv, mySiema.selector.nextSibling);

    // setInterval(() => mySiema.next(), 6000);

}

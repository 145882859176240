import * as klaro from './klaro';

var alreadyInit = false;

var klaroConfig = {
    // You can customize the ID of the DIV element that Klaro will create
    // when starting up. If undefined, Klaro will use 'klaro'.
    elementID: 'klaro',

    // How Klaro should store the user's preferences. It can be either 'cookie'
    // (the default) or 'localStorage'.
    storageMethod: 'cookie',

    // You can customize the name of the cookie that Klaro uses for storing
    // user consent decisions. If undefined, Klaro will use 'klaro'.
    cookieName: 'klaro',

    // You can also set a custom expiration time for the Klaro cookie.
    // By default, it will expire after 120 days.
    cookieExpiresAfterDays: 365,

    // You can change to cookie domain for the consent manager itself.
    // Use this if you want to get consent once for multiple matching domains.
    // If undefined, Klaro will use the current domain.
    //cookieDomain: '.github.com',

    // Put a link to your privacy policy here (relative or absolute).
    privacyPolicy: '/datenschutz',

    // Defines the default state for applications (true=enabled by default).
    default: false,

    // If "mustConsent" is set to true, Klaro will directly display the consent
    // manager modal and not allow the user to close it before having actively
    // consented or declines the use of third-party apps.
    mustConsent: false,

    // Show "accept all" to accept all apps instead of "ok" that only accepts
    // required and "default: true" apps
    acceptAll: true,

    // replace "decline" with cookie manager modal
    hideDeclineAll: true,

    // You can define the UI language directly here. If undefined, Klaro will
    // use the value given in the global "lang" variable. If that does
    // not exist, it will use the value given in the "lang" attribute of your
    // HTML tag. If that also doesn't exist, it will use 'en'.
    //lang: 'en',

    // You can overwrite existing translations and add translations for your
    // app descriptions and purposes. See `src/translations/` for a full
    // list of translations that can be overwritten:
    // https://github.com/KIProtect/klaro/tree/master/src/translations

    // Example config that shows how to overwrite translations:
    // https://github.com/KIProtect/klaro/blob/master/src/configs/i18n.js
    translations: {
        // If you erase the "consentModal" translations, Klaro will use the
        // bundled translations.
        de: {
            title: 'Einstellungen',
            consentNotice: {
                learnMore: 'Einstellungen',
                description: 'Für folgende Zwecke nutzt diese Website Cookies und Verbindungen zu Drittanbietern: {purposes}'
            },
            consentModal: {
                title: 'Einstellungen',
                description:
                    'Unsere Webseite verwendet Cookies und Verbindungen zu Drittanbietern. Notwendige Cookies werden in ihrem Browser gespeichert, um die Basisfunktionalität bereitzustellen. Darüber hinaus nutzen wir auch Cookies von Drittanbietern, um zu analysieren und zu verstehen, wie unser Webangebot genutzt wird.',
            },
            core: {
                description: 'Cookies die für den technischen Betrieb der Website notwendig sind.',
            },
            mapbox: {
                description: 'Zur Anzeige einer Karte nutzen wir den externen Dienst von Mapbox.',
            },
            purposes: {
                functional: 'Funktionalität',
                maps: 'Karten'
            },
            acceptSelected: 'Auswahl akzeptieren',
        },
    },

    // This is a list of third-party apps that Klaro will manage for you.
    apps: [
        {
            name: "core",
            title: "Funktionalität",
            purposes: ["functional"],
            cookies: [""],
            required: true
        },
        {
            // The name of the app, used internally by Orejime.
            // Each name should match a name of a <script> tag defined in the
            // "Changing your existing third-party scripts" documentation step.
            name: "mapbox",

            // The title of you app as listed in the consent modal.
            title: "Mapbox",

            // A list of regex expressions, strings, or arrays, giving the names of
            // cookies set by this app. If the user withdraws consent for a
            // given app, Orejime will then automatically delete all matching
            // cookies.
            //
            // See a different example below with the inline-tracker app
            // to see how to define cookies set on different path or domains.
            cookies: [],

            // Optional. The purpose(s) of this app. Will be listed on the consent notice.
            // Do not forget to add translations for all purposes you list here.
            purposes: ["maps"],

            // Optional. A callback function that will be called each time
            // the consent state for the app changes. Passes
            // the `app` config as the second parameter as well.
            callback: function () {
                if (alreadyInit) {
                    window.location.reload();
                }
                alreadyInit = true;
            },

            // Optional. If "required" is set to true, Orejime will not allow this app to
            // be disabled by the user.
            // default to false
            required: false,

            // Optional. If "optOut" is set to true, Orejime will load this app even before
            // the user gave explicit consent.We recommend always leaving this "false".
            // defaults to false
            optOut: false,

            // Optional. If "default" is set to true, the app will be enabled by default
            // Overwrites the global "default" setting.
            // defaults to the value of the gobal "default" setting
            default: false,

            // Optional. If "onlyOnce" is set to true, the app will only be executed
            // once regardless how often the user toggles it on and off.
            // defaults to false
            onlyOnce: true,
        },
    ],
};

document.addEventListener("DOMContentLoaded", function () {

    klaro.renderKlaro(klaroConfig);

   /* jQuery(document).on('openSettings', function (e, id) {
        if (!JSON.parse(getCookie("klaro"))) {
            jQuery('.cookie-notice').addClass('alert_cookies');
            setTimeout(function () {
                jQuery('.cookie-notice').removeClass('alert_cookies');
            }, 250);
        }
        klaro.show(klaroConfig);
        setTimeout(function () {
            jQuery('label[for="'+ id +'"]').parent().parent().addClass('to-change');
        }, 200);
        setTimeout(function () {
            jQuery('label[for="'+ id +'"]').parent().parent().addClass('changed');
            jQuery('label[for="'+ id +'"]').parent().parent().removeClass('to-change');
        }, 1000);
    });*/


    if (document.querySelector('.settings_link__youtube')) {
        let settings_link = document.querySelectorAll('.settings_link__youtube');
        settings_link.forEach(link =>
            link.addEventListener("click", function (e) {
                // jQuery(document).trigger('openSettings', ['app-item-youtube']);
                e.preventDefault();
                return false;
            })
        );
    }

    if (document.querySelector('.settings_link__map')) {
        let settings_link = document.querySelectorAll('.settings_link__map');
        settings_link.forEach(link =>
            link.addEventListener("click", function (e) {
                klaro.show(klaroConfig);

                // jQuery(document).trigger('openSettings', ['app-item-mapbox']);
                e.preventDefault();
                return false;
            })
        );
    }

    if (document.querySelector('.settings_link')) {
        let settings_menu = document.querySelector('.settings_link');
        settings_menu.addEventListener("click", function (e) {
                klaro.show(klaroConfig);
            // jQuery(document).trigger('openSettings', [null]);
            e.preventDefault();
            return false;
        });
    }

    function getCookie(name) {
        var re = new RegExp(name + "=([^;]+)");
        var value = re.exec(document.cookie);
        return (value != null) ? unescape(value[1]) : null;
    }
});
